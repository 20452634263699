.ant-layout-content {
  padding-inline: 16px !important;
}
.ant-page-header {
  padding-inline: 0 !important;
}
/*.ant-d*/
.ant-drawer-body {
  padding-inline: 16px !important;
}
.ant-drawer-header {
  padding-inline: 16px !important;
}
/*.ant-card-bordered {*/
/*  border: 1px solid #424242;*/
/*}*/
.ant-card-body>.ant-form-item:last-child {
  margin-bottom: 0;
}
.ant-card-body>.ant-pro-form-list:last-child>.ant-form-item {
  margin-bottom: 0;
}

.ant-form-item-explain-error {
  font-size: 10px;
}

.statistic-sm .ant-statistic-title { margin-bottom: 0; font-size: 0.75rem }
.statistic-sm .ant-statistic-content { font-size: 1rem; }


.m-0 {
  margin: 0 !important;
}
.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 6px;
}
.mr-2 {
  margin-right: 12px;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 6px;
}
.mb-2 {
  margin-bottom: 12px;
}
.mb-3 {
  margin-bottom: 16px;
}
.mb-4 {
  margin-bottom: 24px;
}
.ml-1 {
  margin-left: 6px;
}
.ml-2 {
  margin-left: 12px;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 6px;
}
.mt-2 {
  margin-top: 12px;
}
.mt-3 {
  margin-top: 16px;
}
.mt-4 {
  margin-top: 24px;
}

.fs-2 {
  font-size: 16px;
}
.fs-1 {
  font-size: 14px;
}
.fs-0 {
  font-size: 12px;
}

.pb-2 {
  padding-bottom: 12px;
}


.pointer {
  cursor: pointer;
}
.d-flex {
  display: flex;
}
.d-none { display: none !important; }
.no-border {
  border: 0;
}
.nowrap {
  white-space: nowrap;
}
.multiline {
  white-space: pre-line;
}
.uppercase {
  text-transform: uppercase;
}

.w-100 {
  width: 100%;
}

.op-30 {
  opacity: 0.30;
}



.contacts-list .ant-form-item{
  margin-bottom: 0;
}

.radio-as-buttons {
  display: flex;
  flex-direction: column;
}
.radio-as-buttons label {
  margin-bottom: 6px;
  border-radius: 0px;
  display: block;
  border-width: 1.02px;
}
.radio-as-buttons label:last-child {
  margin-bottom: 0px;
}

.radio-as-buttons label:before {
  display: none;
  width: 0 !important;
}
.ant-card:not(.ant-card-bordered) > .ant-card-head {
  border: 0;
}

.master-times {
  /*margin: 0 -3px 0 -3px;*/
  /*display: flex;*/
  /*flex-wrap: wrap;*/
  margin-top: 12px;
  margin-bottom: 12px;
}
.master-times .ant-badge{
  /*flex: 1 0 calc(20% - 6px);*/
  /*margin: 3px;*/
  width: 100%;
}
.master-times .master-time {
    cursor: pointer;
    padding: 4px;
    width: 100%;
    /*text-align: center;*/
    white-space: nowrap;
  height: 50px;
}

.lead-cards .lead-card.accepting {
  background-color: #2b2111;
}
.lead-cards .lead-card.accepting-by-me {
  background-color: #111a2c;
}
.lead-cards .lead-card.accepted {
  background-color: #162312;
}

.edit-order-cards .edit-order-card {
  border-bottom: 1px solid #373737;
}
.edit-order-cards .edit-order-card:last-child {
  border-bottom: 0;
}


.lead-types {
  /*margin-top: 24px;*/
}
.lead-types .ant-card {
  border: 0;
  border-bottom: 1px solid #373737;
}
.lead-types .ant-card:last-child {
  border: 0;
}
.lead-types .lead-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lead-types .lead-type .ant-card-meta {
  flex-basis: 90%;
}
.lead-types .lead-type .ant-card-meta-title {
  font-weight: normal;
  font-size: 14px;
}
.lead-types .lead-type.order .ant-card-meta-title {
  color: #52c41a;
}
.lead-types .lead-type.consultation .ant-card-meta-title {
  color: #faad14;
}
.lead-types .lead-type.wrong .ant-card-meta-title {
  color: #ff4d4f;
}
.lead-types .lead-type.duplicate .ant-card-meta-title {
  color: #faad14;
}


.order-edits {
  /*margin-top: 24px;*/
}
.order-edits .ant-card {
  border: 0;
  border-bottom: 1px solid #373737;
}
.order-edits .ant-card:last-child {
  border: 0;
}
.order-edits .order-edit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.order-edits .order-edit .ant-card-meta {
  flex-basis: 90%;
}
.order-edits .order-edit .ant-card-meta-title {
  font-weight: normal;
  font-size: 14px;
}
.order-edits .order-edit.done .ant-card-meta-title {
  color: #52c41a;
}
.order-edits .order-edit.long_repair .ant-card-meta-title {
  color: #52c41a;
}
.order-edits .order-edit.reopen .ant-card-meta-title {
  color: #52c41a;
}
.order-edits .order-edit.diagnostics .ant-card-meta-title {
  color: #faad14;
}
.order-edits .order-edit.change_master_time .ant-card-meta-title {
  color: #faad14;
}
.order-edits .order-edit.in_decision .ant-card-meta-title {
  color: #faad14;
}
.order-edits .order-edit.comment .ant-card-meta-title {
  color: #8c8c8c;
}
.order-edits .order-edit.canceled .ant-card-meta-title {
  color: #ff4d4f;
}
.order-edits .order-edit.complaint .ant-card-meta-title {
  color: #ff4d4f;
}
.order-edits .order-edit.warranty .ant-card-meta-title {
  color: #ff4d4f;
}


.orders .order-card {
  border: 0;
  display: flex;
  flex-direction: column;
}
.orders .order-card .ant-card-body {
  flex: 1;
}

.customer-orders .order-card {
  margin-bottom: 16px;
}


.master-edits {
  /*margin-top: 24px;*/
}
.master-edits .ant-card {
  border: 0;
  border-bottom: 1px solid #373737;
}
.master-edits .ant-card:last-child {
  border: 0;
}
.master-edits .master-edit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.master-edits .master-edit .ant-card-meta {
  flex-basis: 90%;
}
.master-edits .master-edit .ant-card-meta-title {
  font-weight: normal;
  font-size: 14px;
}
.master-edits .master-edit.edit .ant-card-meta-title {
  color: #8c8c8c;
}
.master-edits .master-edit.schedule .ant-card-meta-title {
  color: #8c8c8c;
}
.master-edits .master-edit.qr-code .ant-card-meta-title {
  color: #1668dc;
}
.master-edits .master-edit.cash-data .ant-card-meta-title {
  color: #52c41a;
}

.master-edits .master-edit.ban .ant-card-meta-title {
  color: #ff4d4f;
}


.card-as-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-as-button .ant-card-meta {
  flex-basis: 90%;
}
.card-as-button .ant-card-meta-title {
  font-weight: normal;
}

.c-green {
  color: #52c41a !important;
}
.c-gold {
  color: #faad14 !important;
}
.c-red {
  color: #ff4d4f !important;
}
.c-yellow {
  color: #faad14 !important;
}
.c-gray {
  color: #8c8c8c !important;
}
.c-blue {
  color: #1668dc !important;
}

.ant-card-actions:has(.ant-dropdown-open) {
  background: #1f1f1f;
  transition: all 0.2s;
}


.ant-pro-form-list-container {
  flex: 1
}

.master-list .master.banned {
  background: #2a1215;
}
.master-list .master.banned .ant-card-actions {
  background: #2a1215;
}


.time-slots {
  display: grid;
}
.time-slots .ant-badge
{
  width: 100%;
}
.time-slots .ant-tag
{
  text-align: center;
}

.calendar {
  display: grid;
  margin-left: -4px;
  margin-right: -4px;
}
.calendar .ant-tag {
  width: 100%;
  text-align: center;
  margin-right: 0;
}

.address-geotag {
  background: #2a1215;
}
.address-geotag.enabled {
  background: #142a12;
}

.master-orders-table table thead tr th { padding: 4px 16px !important; }



.diff_pct { white-space: nowrap; color: #8c8c8c; font-size: 12px; margin-left: 4px;}
.diff_pct.positive { color: #52c41a; }
.diff_pct.negative { color: #ff4d4f; }
.diff_pct .ant-tag { border-radius: 50%; margin-right: 4px; padding: 1px 5px;}
.diff_pct.positive .round { background-color: #162312; }
.diff_pct.negative .round { background-color: #2a1215; }

.ant-pro-page-container-children-container {
  padding-inline: 0;
}
@media (max-width: 425px) {
}

.tasks .tasks-list .tasks-list-task {
  padding: 12px;
  font-size: 13px;
  width: 100%;
  margin-bottom: 12px;
}

.tasks .tasks-list .tasks-list-task:last-child {
  margin-bottom: 0;
}
